<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="subgroups"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: 'OrganisationSubGroupList',
  computed: {
    ...mapGetters('subgroup', ['loading', 'error', 'subgroups', 'meta']),
    ...mapGetters('organisation', ['organisation'])
  },
  methods: {
    editItem(item) {
      this.$router.push('/subgroups/' + item.id + '/hosts');
    },
    load() {
      if (this.organisation.id) {
        store.dispatch('subgroup/organisation', {
          organisation_id: this.organisation.id
        });
      }
    },
    paginateAction(item) {
      console.log(item);
      if (item == 'next') {
        store.dispatch('subgroup/paginate', this.links.next);
      } else if (item == 'previous') {
        store.dispatch('subgroup/paginate', this.links.prev);
      }
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    'organisation.id': function () {
      this.load();
    }
  },
  data: () => ({
    tabs: null,
    headers: [
      { text: 'Group', value: 'group_title' },
      { text: 'Title', value: 'title' }
    ],
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Organisations',
        exact: true,
        to: '/organisations'
      },
      {
        text: 'Organisation',
        disabled: true,
        exact: true,
        to: '/organisations/:id'
      }
    ]
  })
};
</script>
